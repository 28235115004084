<template>
	<div>
		<qy-top-nav title="切换门店" />
		<div
			style="position: fixed;width:100%;height:30px;background:#fff;z-index:10;top:50px;color:#333;font-size: 14px;padding:5px 10px;">
			<div style="text-align: center;font-weight: bold;padding-top: 5px;;">
				已选：{{proxiedUser.userName||'未选择门店'}}
			</div>
		</div>
		<div style="height: 40px;"></div>
		<div :style="{maxHeight: bodyHeight,overflow:'auto'}">
			<div v-for="item in dataList"
				style="color:#333;background-color: #fff;margin: 10px;padding:12px 15px;;border-radius: 5px;"
				:class="{selectedCustmor:item.userId==proxiedUser.userId}">
				<div style="font-size: 14px;color: #333;line-height: 24px;position: relative;">
					<div>姓名：{{item.userName}}</div>
					<div>电话：{{item.phonenumber}}</div>
					<!-- <div
						style="position: absolute; transform: rotate(45deg); font-size: 12px; color: red; right: -11px; top: 0;  padding: 5px 8px 5px 8px; line-height: 12px;">
						<van-tag :type="item.tag=='A'?'danger':'primary'">{{getDict(tagList,item.tag)}}</van-tag>
					</div> -->
				</div>
				<div
					style="display: flex;justify-content: flex-end;border-top: 1px solid #ededed;margin-top: 10px;padding-top: 15px;">
					<van-button style="margin-left: 5px;" type="warning" icon="passed" round plain size="small"
						@click="selectCustomer(item)">选择门店</van-button>
				</div>
			</div>
		</div>
		<van-list v-model="loading" :finished="finished" :offset="offset" finished-text="没有更多了" @load="onLoad">
		</van-list>
		<div style="position: fixed;bottom: 1px;z-index: 10;width: 100%;padding:5px 0;background-color: #fff;">
			<div class="flex-between">
				<span></span>
				<van-button type="danger" size="small" @click="navigateTo('/customerEdit')"> 新增门店</van-button>
				<van-button type="danger" size="small" plain @click="selectCustomer()"> 取消设置</van-button>
				<span></span>
			</div>
		</div>
	</div>
	</div>
</template>
<script type="text/javascript">
	export default {
		name: "customerList",
		components: {},
		data() {
			return {
				loading: false,
				finished: false,
				offset: 50,
				bodyHeight: 0,
				pageSize: 10,
				pageNum: 1,
				proxiedUser: {},
				dataList: [],
				tagList: [],
				tempObj: {},
			}
		},
		created() {
			this.proxiedUser = this.getProxiedUser();
		},
		methods: {

			onLoad() {
				//是否已经登录
				let isLogin = this.checkLogin();
				if (!isLogin) {
					return;
				}
				this.doRequestProxyStg();
			},
			doRequestProxyStg() {
				this.doPost("/api/proxy/oper/listClient", {
					pageNum: this.pageNum,
					pageSize: this.pageSize,
					orderByColumn: "u.create_time",
					isAsc: "desc"
				}, (rs) => {
					this.onLoadSuccess(rs);
				});
			},
			onLoadSuccess(rs) {
				this.loading = false;
				if (this.pageNum == 1) {
					this.tagList = rs.otherData;
				}
				this.dataList = this.dataList.concat(rs.rows);
				this.finished = this.pageNum * this.pageSize >= rs.total;
				this.pageNum++;
			},
			selectCustomer(user) {
				if (user) {

					this.proxiedUser = user;
					this.setData("proxiedUser", user);
				} else {
					this.proxiedUser = {};
					this.removeData("proxiedUser")
				}
			}

		},
		mounted() {
			this.bodyHeight = document.documentElement.clientHeight - 170 + "px";
		}


	}
</script>
<style type="text/css" scoped="">
	.selectedCustmor {

		border: 1px solid red;
	}
</style>